<template>
	<v-container fluid>
		<v-container v-if="suppliers.length == 0">{{ $t("suppliers.loading") }}</v-container>
		<v-container>
			<h1 class="centrovane">{{ $t("suppliers.title")}}</h1>
			<v-row dense>
				<v-col class="d-flex flex-column" v-for="n in suppliers" :key="n['id']" cols="12" sm="6" md="4" lg="3">
					<v-card class="flex d-flex flex-column" outlined tile elevation="24" :href="n['url']" target="_blank">
						<v-row dense class="incenter seda">
							<v-col >
								<v-card-title class="headline">{{ n['company_full_name'] }}</v-card-title>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col class="incenter">
								<v-avatar class="profile" size="120" tile>
									<v-img contain :src="'/pictures/pics/suppliers/' + n['logo']"></v-img>
								</v-avatar>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col>
								<v-card-subtitle class="textik">{{ n["field"] }}</v-card-subtitle>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data: () => ({
		suppliers: [],
	}),
	methods: {},
	mounted() {
		ApiService.get("/suppliers").then((response) => {
			this.suppliers = response.data;
		}).catch(() => {});
	},
};
</script>

<style scoped>
.imager {
	max-height: 100px;
	padding-top: 10px;
}
.textik {
	text-align: justify;
}
.centrovane {
	text-align: center;
}
.incenter {
	text-align: center;
}
.headline {
	font-size: 1.2rem !important;
	display: block;
	padding: 0;
}
.seda {
	background-color: rgb(245, 245, 245);
	margin: 0;
	padding:0;
}
</style>
